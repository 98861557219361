<template>
  <PflegeBaseSectionTemplate
    v-editable="props.blok"
    :backgroundColor="getPflegeColornameFromHex(props.blok.backgroundColor.value)"
    :data-uid="props.blok._uid"
    :anchor="anchor"
    :textAlign="props.blok.textAlign"
  >
    <template v-if="props.blok.media?.length > 0" #media>
      <WPElementMedia
        :blok="props.blok.media[0]"
        :aspectRatio="{
          desktop: aspectRatios.xs,
          tablet: aspectRatios.s,
          mobile: aspectRatios.s
        }"
      />
    </template>

    <template v-if="props.blok.intro?.length > 0" #intro>
      <IntroComponent :blok="props.blok.intro[0]" />
    </template>
    <template v-if="contentFlag" #content>
      <template v-for="copy in props.blok.copy">
        <WPElementRichtext
          v-if="copy.component === 'WPElementRichtext'"
          :key="copy._uid"
          :blok="copy"
        />
      </template>
      <template v-for="content in props.blok.button">
        <WPElementButton
          v-if="content.component === 'WPElementButton'"
          :key="content._uid"
          :blok="content"
        />
      </template>
    </template>

    <template v-if="props.blok.secondDivider?.length > 0" #secondDivider>
      <Divider :blok="props.blok.secondDivider" />
    </template>

    <template v-if="props.blok.closing?.length > 0" #closing>
      <ClosingComponent :blok="props.blok.closing[0]" />
    </template>

    <template v-if="props.blok.thirdDivider?.length > 0" #thirdDivider>
      <Divider :blok="props.blok.thirdDivider" />
    </template>
  </PflegeBaseSectionTemplate>
</template>

<script setup>
import { PflegeBaseSectionTemplate } from '@shared'

const props = defineProps({
  blok: {
    type: Object,
    required: true
  }
})
const anchor = computed(() => {
  return props.blok.intro?.length > 0 && props.blok.intro[0].anchor !== null
    ? props.blok.intro[0].anchor
    : ''
})
const contentFlag = props.blok.copy.length > 0 || props.blok.button.length > 0
const { getPflegeColornameFromHex } = usePflegeUtils()
const mainComponentProvide = useMainComponentProvide()
mainComponentProvide.init()
</script>
